<template>
  <td class="px-0">
    <v-layout column>
      <v-layout style="height: 24px" class="my-1">
        <IconButton
          noYMargin
          @clicked="$emit('edit', item)"
          icon="mdi-pencil"
          color="primary"
          bgcolor="transparent"
        ></IconButton>
        <IconButton
          noYMargin
          @clicked="$emit('remove', item)"
          icon="mdi-trash-can-outline"
          color="error"
          bgcolor="transparent"
        ></IconButton>
      </v-layout>
      <v-flex
        v-for="(object, index) in value.slice(1)"
        :key="index"
        :style="{backgroundColor: index % 2 ? '' : '#F9F9F9', height: '32px', width: '100%'}"
      ></v-flex>
    </v-layout>
  </td>
</template>

<script>
export default {
  props: {
    header: {
      default: undefined
    },
    item: {
      default: undefined
    },
    value: {}
  },
  components: {
    IconButton: () => import("../buttons/IconButton")
  }
};
</script>
